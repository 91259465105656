@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

// Lourens z'n magic

$light: #ffffff;
$grey: #f1f1f1;
$primary: #2bbca3;
$dark: #2a2a2a;
$barH: 80px;

body {
	background: $grey;
	padding: 0 0 $barH 0;
	font-family: 'Roboto', Roboto, Helvetica, Arial, sans-serif;
	color: $dark;
	line-height: 1.6;
}

h1, h2 {
	font-weight: bold;
	line-height: 1.3;
	margin-bottom: .5em;
}

h1 {
	font-size: 2em;
}

h2 {
	font-size: 1.8em;
}

a:link, a:visited {
	color: $primary;
	text-decoration: none;
}

a:hover, a:active {
	text-decoration: underline;
}

.btn,
button {
	display: block;
	padding: 8px 12px;
	background: $primary;
	color: $light;
	font-weight: bold;
	font-size: 1rem;
	border-width: 0;
	border-bottom: 2px solid darken($primary, 5%);
	margin: 5px 0 15px 0;
	cursor: pointer;
}

.bottombar {
	background: $primary;
	position: fixed;
	bottom: 0;
	background: $dark;
	height: 50px;
	line-height: 50px;
	left: 0;
	z-index: 10;
	width: 100%;
	text-align: center;
	color: $light;
	font-size: .7rem;
	font-weight: bold;

	.prev, .next {
		color: $primary;
		font-weight: normal;
		position: relative;
		cursor: pointer;
	}
	.prev {
		padding-right: 35px;
	}
	.next {
		padding-left: 35px;
	}
	.prev:after {
		border-bottom: 5px solid $primary;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		height: 0px;
		width: 0px;
		content: " ";
		display: block;
		position: absolute;
		top: 50%;
		margin-top: -2px;
		right: 20px;
	}
	.next:after {
		border-top: 5px solid $primary;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		height: 0px;
		width: 0px;
		content: " ";
		display: block;
		position: absolute;
		top: 50%;
		margin-top: -2px;
		left: 20px;
	}
}


.intro {
	max-width: 1000px;
	width: 90%;
	padding: 100px 0;
	margin: 0 auto;
	display: table;

	.dark-btn {
		background: #383838 url(/arrow.png) no-repeat center right;
		padding: 8px 50px 8px 18px;
		display: inline-block;
		color: #fff;
		text-decoration: none;
		border-bottom: 2px solid rgba(0,0,0,.4);
		font-size: 1.2rem;
		font-weight: bold;
		text-transform: uppercase;
		margin-top: 10px;
	}
}

.container {
	width: 100%;
	margin: 0 auto 50px auto;
	max-width: 1000px;
	background: $light;
	padding: 30px 20px;
} 

.logo {
	width: 100%;
	max-width: 468px;
	padding: 0 15px 30px 0;
	height: auto;
	display: block;
}

.questions {
	list-style-type: decimal;
	list-style-position: inside;
	border-bottom: 3px solid #eee;
	padding-bottom: 50px;
	margin-bottom: 50px;

	li {
		color: $primary;
		font-weight: bold;
		position: relative;
		font-size: 1.3rem;
		padding: 50px 0;
		counter-reset: answer;  
		opacity: .4;
		transition: all .5s;

		&:first-of-type,
		&.attention {
			opacity: 1;
		}

		em {
			position: absolute;
			bottom: 20px;
			left: 0;
			font-size: 1rem;
			font-weight: normal;
		}

		&.star {
			strong {
				color: $dark;
				line-height: 1.4;
				display: inline;
			}
			p {
				font-size: 0;
			}
			label {
				width: 20%;
				color: $dark;
				display: inline-block;
				font-size: .6rem;
				padding: 0;
				margin: 0;
				font-weight: 400;
				text-align: center;
				line-height: 1.4;
				cursor: pointer;
				position: relative;

				&:before {
					display: block;
					margin: 10px 0 20px 0;
					background: $grey;
					counter-increment: answer;               
	 			 	content: counter(answer);
					width: 100%;
					height: $barH;
					line-height: $barH;
					text-align: center;
					font-size: 1.6rem;
					font-weight: bold;
					border-bottom: 3px solid rgba(0,0,0,.1);
					transition: all .2s;
					z-index: 2;
					position: relative;
				}
				&:after {
					position: absolute;
					top: 87px;
					left: 50%;
					content: " ";
					z-index: 0;
					display: block;
					margin-left: -10px;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-top: 10px solid rgba(0,0,0,.15);
				}
				&:nth-of-type(even):before {
					background: darken($grey,5%);
				}
				&:nth-of-type(even):after {				
					border-top: 10px solid rgba(0,0,0,.2);
				}
				span {
					padding: 0 5px;
					height: 35px;
					display: inline-block; 
					vertical-align: top;
				}
			}

			input {
				position: absolute;
				left: -1000px;
			}
			input:checked + label:before {
				background-color: $primary;
				color: $light;
			}
			input:checked + label:after {
				border-top-color: darken($primary,5%);
			}


		}

	}
}


.last-questions {


	.half {
		padding: 50px 0 0 40px;
	}

	label {
		display: inline-block;
		width: 100%;
		margin-top: 10px;
	}

	input, 
	select {
			padding: 5px;
			width: 100%;
			font-size: 1rem;
		option {
			padding: 5px;
		}
	}

	.last-form {
		padding-left: 40px;

		button {
			margin-top: 10px;
			width: 100%;
			text-align: center;
		}

		.results {
			height: 40px;
			border-top: 2px solid #f4f4f4;
			padding: 10px 0;
			margin: 10px 0;
			display: none;
		}
	}
}


.login-form {
	width: 400px;
	background: $light;
	padding: 30px;
	margin: 50px auto;

	label {
		font-weight: bold;
		display: block;
	}

	.form-control {
		border: 1px solid #ccc;
		padding: 5px;
		width: 100%; 
		font-size: 1rem;
	}
}

.admin-section {
	max-width: 1000px;
	width: 90%;
	padding: 50px;
	margin: 50px auto;
	background: $light;


	.admin-links {
		text-align: right;
	}
	.questions {
		padding-bottom: 40px;
		li {
			padding: 20px;
			opacity: 1;
			&:nth-of-type(odd) {
				background: #f5f5f5;
			}
		}
		input {
			padding: 5px 10px;
			font-size: 1.2rem;
			border: 1px solid #ccc;
			width: 45%;
		}
		select {
			padding: 10px;
			font-size: 1.2rem;
			width: 20%;
		}
	}

	a.btn {
		display: inline-block;
		padding: 8px 12px;
		background: $primary;
		color: $light;
		font-weight: bold;
		font-size: 1rem;
		border-width: 0;
		border-bottom: 2px solid darken($primary, 5%);
		margin: 5px 0 15px 0;
		cursor: pointer;

		&:link, &:visited, &:hover, &:active {
			text-decoration: none;
		}
	}
	.btn-save {
		float: right;
	}

	span.remove {
		transform: rotate(45deg);
		display: inline-block;
		cursor: pointer;
		padding: 10px;
		background: rgba(0,0,0,.05);
		border-radius: 30px;
		line-height: 8px;
		text-align: center;
		width: 30px;
		height: 30px;
	}

	.shuffle {
		cursor: pointer;
		border: 1px solid $grey;
		padding: 10px;
	}
}



@media all and (min-width: 520px) {

	.container {
		padding: 50px 10% 100px 10%;
	}

	.bottombar {
		font-size: 1rem;
	}

	.questions li.star label { 
		font-size: .8rem;
	}

	.half {
		width: 50%;
		display: table-cell;
		vertical-align: top;
	}

	.admin-section .half:nth-of-type(even) {
		padding-right: 25px;
	}
	.admin-section .half:nth-of-type(odd) {
		padding-left: 25px;
	}

	.outro {
		width: 30%;
		display: table-cell
	}


	.last-questions {

		width: 70%;
		display: table-cell;
	}
}





